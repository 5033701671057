<template>
  <div>

    <slot v-if="!noActivator" name="default" :select="openDialog">
      <v-btn @click="openDialog" :outlined="!text" :text="text" :block="block" :color="wsACCENT" class="noCaps">
        {{ $t('ChooseFromStorage') }}
        <v-icon class="ml-1">mdi-magnify</v-icon>
      </v-btn>
    </slot>

    <ws-dialog
        width="800"
        v-model="displayDialog"
        :title="$t('ChooseFile')"
        :show-save="false"
    >
      <ws-text-field icon="mdi-magnify" v-model="search" />

      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="search"
          dense
          :row-action="selectFile"
      >


        <template #item.name="{item}">
          <h5>
            <v-icon :color="wsATTENTION">{{ getIcon(item.mime) }}</v-icon>
            {{ item.name }}
          </h5>
        </template>

        <template #item.size="{item}">
          <div>
            <h5>{{ PARSE_FILE_SIZE(item.size)    }}</h5>
          </div>
        </template>

        <template #item.relations_count="{item}">
          <div>
            <h5 class="text-center" v-if="item.relations_count > 0"> {{ item.relations_count   }}</h5>
          </div>
        </template>
        <template #item.date_added="{item}">
          <h5 class="font-weight-medium"> {{ PARSE_DATE(item.date_added*1000 ) }}</h5>
        </template>

      </ws-data-table>

    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "wsStorageFilePicker",
  props : {
    value : {
      default : null
    },
    video : {
      type : Boolean,
      default : false
    },
    image : {
      type : Boolean,
      default : false
    },
    block : {
      type : Boolean,
      default : false
    },
    text : {
      type : Boolean,
      default : false
    },
    trigger : {
      type : Number,
    },
    noActivator : {
      type : Boolean,
      default : false
    },
    public : {
      type : Boolean,
      default : false
    },
    formats : {
      type : Array,
      default() { return [] }
    }
  },
  data() {
    return {
      search: '',
      displayDialog : false,
      file : null,
      items : [],
    }
  },
  computed : {
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0 ) {
        return []
      }

      if ( this.formats.length > 0 ) {
        items = items.filter(el=> this.formats.includes(el.mime.split('/')[1]) )
      }

      if ( this.public ) {
        items = items.filter(el=>el.url && el.url !== 'google' )
      }

      if ( this.video ) {
        items = items.filter(el=>el.mime.includes('video'))
      }
      if ( this.image ) {
        items = items.filter(el=>el.mime.includes('image'))
      }

      return items
    },
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('DateCreated') , value : 'date_added' },
        { text : this.$t('FileSize') , value : 'size' },
      ]
    },
  },
  watch : {
    trigger() {
      this.openDialog()
    },
    value : {
      handler() {
        if ( JSON.stringify(this.value) !== JSON.stringify(this.file) ) {
          this.file = Object.assign({} , this.value)
        }
      },
      deep : true
    },
    file : {
      handler() {
        if ( JSON.stringify(this.value) !== JSON.stringify(this.file) ) {
          this.$emit('input' , Object.assign({} , this.file) )
        }
      },
      deep : true
    },
  },
  methods : {
    ...mapActions('storage', [ 'GET_STORAGE_BROWSER' ]),

    selectFile(item) {
      this.file = item
      this.displayDialog = false
    },
    getIcon(mime) {

      if (!mime ) {
        return 'mdi-file-outline'
      }

      if ( mime.includes('image') ) {
        return 'mdi-file-image-outline'
      }

      if ( mime.includes('video') ) {
        return 'mdi-file-video-outline'
      }

      return 'mdi-file-outline'

    },

    openDialog() {
      this.getStorage()
      this.displayDialog = true
    },
    async getStorage() {
      let result = await this.GET_STORAGE_BROWSER()
      if ( !result || result === true) {
        return
      }
      result.reverse()
      this.items = result
    }
  },
  mounted() {
    if ( this.value ) {
      this.file = Object.assign({} , this.value)
    }

    this.displayDialog = this.display
  }
}
</script>

<style scoped>

</style>