var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.noActivator)?_vm._t("default",[_c('v-btn',{staticClass:"noCaps",attrs:{"outlined":!_vm.text,"text":_vm.text,"block":_vm.block,"color":_vm.wsACCENT},on:{"click":_vm.openDialog}},[_vm._v(" "+_vm._s(_vm.$t('ChooseFromStorage'))+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-magnify")])],1)],{"select":_vm.openDialog}):_vm._e(),_c('ws-dialog',{attrs:{"width":"800","title":_vm.$t('ChooseFile'),"show-save":false},model:{value:(_vm.displayDialog),callback:function ($$v) {_vm.displayDialog=$$v},expression:"displayDialog"}},[_c('ws-text-field',{attrs:{"icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('ws-data-table',{staticClass:"mt-5",attrs:{"items":_vm.itemsFiltered,"headers":_vm.headers,"search":_vm.search,"dense":"","row-action":_vm.selectFile},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('h5',[_c('v-icon',{attrs:{"color":_vm.wsATTENTION}},[_vm._v(_vm._s(_vm.getIcon(item.mime)))]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('h5',[_vm._v(_vm._s(_vm.PARSE_FILE_SIZE(item.size)))])])]}},{key:"item.relations_count",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.relations_count > 0)?_c('h5',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.relations_count))]):_vm._e()])]}},{key:"item.date_added",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.PARSE_DATE(item.date_added*1000 )))])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }